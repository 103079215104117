import SignInLayout from "containers/app/screens/SignIn/layout/SignInLayout"
import {SignInComponent} from "containers/app/screens/SignIn/component/page/SignInComponent"

const SignIn = () => (
  <SignInLayout>
    <SignInComponent/>
  </SignInLayout>
)

export default SignIn
