import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
/* eslint-disable-next-line */
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Button, Checkbox, Col, Input, Row, Space, Typography, notification } from 'antd'
import { Link } from 'react-router-dom'
import { APP_USERS_PASSWORD_NEW_PATH } from 'constants/routes.constants'
import { MailOutlined, UnlockOutlined } from '@ant-design/icons'
import { globalColors } from 'containers/app/data/colors'
import { SignInParams } from 'containers/shared/api/session.api'
import styles from './styles.module.scss'

const validateEmail = (value: string) => {
  let error
  if (!value) {
    error = 'Email is required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9._]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email format'
  }
  return error
}

const validatePassword = (value: string) => {
  let error
  if (!value) {
    error = 'Password is required'
  } else if (value.length < 6) {
    error = 'Password must be at least 6 characters long'
  }
  return error
}

const SignInForm = inject('sessionStore')(
  observer(({ sessionStore }: { sessionStore?: any }) => {
    const onSubmit = useCallback(
      async (values, { setSubmitting }) => {
        try {
          const resp = await sessionStore.signIn(values)
          /* eslint-disable-next-line */
          if (resp.status == 200) {
            window.location.href = '/cabinet'
          } else {
            notification.error({
              message: 'Incorrect email or password',
            })
          }
          setSubmitting(false)
        } catch (e) {
          setSubmitting(false)
          notification.error({
            message: 'Incorrect email or password',
          })
        }
      },
      [sessionStore],
    )

    return (
      <Row>
        <Col span={24}>
          <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit}>
            {(props: FormikProps<SignInParams>) => (
              <Form>
                <Space direction='vertical' size={24} style={{ width: '100%' }}>
                  <Field name='email' validate={validateEmail}>
                    {({ field, meta }: FieldProps) => (
                      <Row>
                        <Col span={24}>
                          <Input
                            {...field}
                            size='large'
                            status={meta?.error && 'error'}
                            disabled={props.isSubmitting}
                            placeholder='Email: username@mail.com'
                            prefix={
                              <div className={styles.inputIconPad}>
                                <MailOutlined style={{ color: globalColors.purple }} />
                              </div>
                            }
                          />
                          <ErrorMessage name='email'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </Col>
                      </Row>
                    )}
                  </Field>
                  <Field name='password' validate={validatePassword}>
                    {({ field, meta }: FieldProps) => (
                      <Row>
                        <Col span={24}>
                          <Input.Password
                            {...field}
                            status={meta?.error && 'error'}
                            size='large'
                            disabled={props.isSubmitting}
                            placeholder='Password: at least 6 characters'
                            prefix={
                              <div className={styles.inputIconPad}>
                                <UnlockOutlined style={{ color: globalColors.purple }} />
                              </div>
                            }
                          />
                          <ErrorMessage name='password'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </Col>
                      </Row>
                    )}
                  </Field>
                  <Row justify='space-between'>
                    <Col>
                      <Space size={8}>
                        <Checkbox /> <Typography.Text>Remember me</Typography.Text>
                      </Space>
                    </Col>
                    <Col>
                      <Link to={APP_USERS_PASSWORD_NEW_PATH}>
                        <Typography.Text type='success'>Forgot password?</Typography.Text>
                      </Link>
                    </Col>
                  </Row>
                  <Button size='large' block type='primary' htmlType='submit' disabled={props.isSubmitting}>
                    Submit
                  </Button>
                </Space>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    )
  }),
)

export default SignInForm
