import Logo from 'assets/reactIcons/Logo'
import { Link } from 'react-router-dom'
import { Col, Row, Space, Typography } from 'antd'
import { APP_USERS_SIGN_UP_PATH } from 'constants/routes.constants'
import { Text } from 'containers/shared/components/typography/Text'
import SignInForm from '../forms/SignInForm'
import styles from './styles.module.scss'

export const SignInComponent = () => (
  <Row justify='center'>
    <Space direction='vertical' size={24} style={{ width: '100%' }}>
      <Col span={24}>
        <Row justify='center'>
          <Logo />
        </Row>
      </Col>
      <Col span={24}>
        <Space size={32}>
          <Typography.Text className={styles.activeLink} type='success'>
            Login
          </Typography.Text>
          <Link to={APP_USERS_SIGN_UP_PATH}>
            <Typography.Text>Sign Up</Typography.Text>
          </Link>
        </Space>
      </Col>
      <Col span={24}>
        <SignInForm />
      </Col>
      <Col span={24}>
        <Row justify='center'>
          <Link to={APP_USERS_SIGN_UP_PATH}>
            <Text size='s'>
              <Typography.Text type='success'>Sign Up</Typography.Text>
            </Text>
          </Link>
        </Row>
      </Col>
    </Space>
  </Row>
)
